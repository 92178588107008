const setIframeHeight = (iframe, height) => {
  if(iframe) {
    iframe.setAttribute('height', `${height}px`);
  }
};

const resizeIframe = data => {
  if (data.iframe_name && data.height) {
    const iframe = document.querySelector(`iframe[name=${data.iframe_name}]`);
    setIframeHeight(iframe, data.height);
  }
  else if(data.iframe_type) {
    // console.log('iframe_type', data.iframe_type);
    const iframe = document.querySelector(`iframe.${data.iframe_type}`);
    setIframeHeight(iframe, data.height);
  }
  
};

const resize = () => {
  if (window.addEventListener) {
    window.addEventListener(
      'message',
      event => {
        resizeIframe(event.data);
      },
      false
    );
  } else if (window.attachEvent) {
    window.attachEvent('onmessage', event => {
      resizeIframe(event.data);
    });
  }
};

export { resize };
